.boxSelection {
  overflow: hidden;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  height: 60vh;
  min-width: 100vw;
  padding-top: 30px;
  align-content: baseline;
  overflow-y: auto;
  mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 95%, rgba(255,255,255,0) 100%);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  @include mobile-landscape {
    min-width: calc(100vw - 185px);
    align-content: center;
    align-self: center;
    height: 80vh;
    padding-top: 90px;
  }

  @include desktop {
    width: 768px;
    min-width: 768px;
    padding: 30px calc(50% - (768px / 2));
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .box {
    height: 100px;
    width: 100px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin: 10px;
    transition: all 0.25s linear;

    @include mobile-portrait {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 40%;
    }

    @include mobile-landscape {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 140px;
      margin: 7px;
    }

    @include desktop {
      height: 135px;
      width: 135px;
    }

    &:last-child {
      margin-bottom: 120px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.1);
    }

    img {
      height: 75px;

      @include mobile-portrait {
        padding-left: 5px;
        height: 50px;
      }

      @include mobile-landscape {
        padding-left: 5px;
        height: 50px;
      }

      @include desktop {
        height: 100px;
      }
    }

    h3 {
      font-size: 10px;
      margin: 0;
      letter-spacing: 1px;

      @include mobile-portrait {
        width: calc(100% - 55px);
        padding: 0 8px;
        font-size: 14px;
      }

      @include mobile-landscape {
        width: calc(100% - 55px);
        padding: 0 8px;
        font-size: 12px;
      }

      @include desktop {
        font-size: 17px;
        font-weight: 400;
      }
    }
  }

  .selected {
    color: #{'rgb(var(--mainColor))'};
    border-color: #{'rgb(var(--mainColor))'};
  }
}
