.mtxSelection {
  display: flex;
  margin: 0;
  min-width: 100vw;
  margin-top: 12px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  @include mobile-portrait {
    font-size: 14px;
    margin: 0;
  }

  @include mobile-landscape {
    margin: 0;
    margin-left: 185px;
    min-width: calc(100vw - 185px);
  }

  @include desktop {
    mask-image: linear-gradient(to bottom, black 80%, transparent 95%);
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  table {
    overflow: hidden;
    border-radius: 10px;
    align-self: center;
    text-align: center;
    box-sizing: content-box;
    border-spacing: 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    @include mobile-portrait {
      width: 300px;
      margin-top: 9px;
    }

    @include mobile-landscape {
      margin-top: 15px;
      align-self: baseline;
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    thead {
      display: block;
      border-radius: 10px 10px 0 0;
      background-color: rgba(0, 0, 0, 0.5);

      @include desktop {
        width: 451px;
      }

      tr {
        th {
          padding: 5px 8px;
          border-right: 1px solid #2a2a2a;

          &:nth-child(1) {
            width: 163px;
            border-left: none;

            @include mobile-portrait {
              width: 141px;
            }

            @include desktop {
              width: 223px;
            }
          }

          &:nth-child(2) {
            width: 34px;

            @include mobile-portrait {
              width: 30px;
            }
          }

          &:nth-child(3) {
            width: 81px;

            @include mobile-portrait {
              display: none;
            }
          }

          &:nth-child(4) {
            width: 43px;
            border-right: none;

            @include mobile-portrait {
              width: 77px;
            }
          }
        }
      }
    }

    tbody {
      display: block;
      border-radius: 0 0 10px 10px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 200px);
      border-spacing: 0px;
      scrollbar-width: none;
      -ms-overflow-style: none;

      @include mobile-portrait {
        height: calc(100vh - 155px);
      }

      @include mobile-landscape {
        height: calc(100vh - 55px);
      }

      @include desktop {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      .mtx {
        height: 60px;
        background-color: rgba(255, 255, 255, 0.07);
        position: relative;

        @include mobile-portrait {
          height: 45px;
        }

        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.12);
        }

        &:last-child {
          margin-bottom: 100px;
          border-radius: 0 0 10px 10px;

          td:last-child {
            border-radius: 0 0 10px 0;
          }
        }

        &:hover {
          cursor: pointer;

          td:first-child {
            color: #{'rgb(var(--mainColor))'};
            text-shadow: 0px 0px 5px #{'rgb(var(--bgColor))'};
          }
        }
        .rare {
          color: var(--rareColor);
        }

        .uncommon {
          color: var(--uncommonColor);
        }

        .common {
          color: var(--commonColor);
        }

        td {
          position: relative;
          padding: 5px;
          border-right: 1px solid rgba(0, 0, 0, 0.3);
          transition: all 0.25s ease-in-out;

          @include desktop {
            padding: 0 5px;
            font-size: 17px;
          }

          &:nth-child(1) {
            width: 169px;

            &:after {
              content: '';
              z-index: -1;
              position: absolute;
              top: 0;
              left: 0;
              width: 0px;
              opacity: 0;
              height: 100%;
              background: #{'rgb(var(--mainColor))'};
              transition: all 0.1s linear;
            }

            @include desktop {
              width: 229px;
            }
          }

          &:nth-child(2) {
            width: 40px;
          }

          &:nth-child(3) {
            width: 88px;
            border: none;
          }

          &:nth-child(4) {
            width: 50px;
            border: none;
            background-color: rgba(0, 0, 0, 0.3);

            @include mobile-portrait {
              display: none;
            }

            img {
              height: 50px;
              max-width: 50px;
            }
          }

          p {
            font-size: 12px;
            position: absolute;
            bottom: 0;
            margin: 0;
            padding-right: 5px;
            border-radius: 0 5px 0 0;
            color: white;
            opacity: 0;
            text-shadow: 1px 1px 2px black;
            transition: all 0.25s linear;
            background: #{'rgb(var(--mainColor))'};

            &.selected {
              opacity: 1;
              left: 5px;
            }

            &.owned {
              opacity: 1;
              left: 0px;
              padding-left: 5px;
              background: #{'rgba(var(--mainColor), 0.4)'};
            }
          }
        }

        &.active {
          td:first-child {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
