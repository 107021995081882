.Ancestor {
	--mainColor: 45, 150, 255;
	--bgColor: 17, 22, 37;
}

.Crucible {
	--mainColor: 195, 65, 0;
	--bgColor: 57, 57, 62;
}

.Sanctum {
	--mainColor: 155, 55, 45;
	--bgColor: 25, 17, 20;
}

.Lake_of_Kalandra {
	--mainColor: 215, 215, 255;
	--bgColor: 42, 37, 40;
}

.Sentinel {
	--mainColor: 75, 120, 255;
	--bgColor: 27, 27, 37;
}

.Harmony {
	--mainColor: 228, 198, 78;
	--bgColor: 22, 3, 81;
}

.Atlantis {
	--mainColor: 51, 152, 209;
	--bgColor: 55, 39, 0;
}

.Midnight_Pact {
	--mainColor: 165, 183, 244;
	--bgColor: 54, 9, 0;
}

.Apollyon {
	--mainColor: 71, 218, 190;
	--bgColor: 41, 24, 66;
}

.Warlord {
	--mainColor: 0, 221, 170;
	--bgColor: 51, 51, 51;
}

.Twilight {
	--mainColor: 254, 183, 8;
	--bgColor: 32, 25, 58;
}

.Angels_Demons {
	--mainColor: 232, 218, 48;
	--bgColor: 43, 43, 43;
}

.Thaumaturgy {
	--mainColor: 159, 212, 43;
	--bgColor: 25, 25, 0;
}

.Polaris {
	--mainColor: 255, 255, 255;
	--bgColor: 16, 16, 16;
}

.Carnival {
	--mainColor: 222, 114, 49;
	--bgColor:37, 10, 37;
}

.Frontier {
	--mainColor: 255, 199, 117;
	--bgColor: 46, 7, 7;
}

.Glimmerwood {
	--mainColor: 82, 255, 129;
	--bgColor: 22, 23, 57;
}

.Fairgraves {
	--mainColor: 85, 218, 226;
	--bgColor: 16, 43, 26;
}

.Oriath {
	--mainColor: 245, 229, 89;
	--bgColor: 37, 6, 6;
}

.Apocalypse {
	--mainColor: 177, 137, 85;
	--bgColor: 31, 1, 1;
}

.Sin_Innocence {
	--mainColor: 193, 161, 45;
	--bgColor: 6, 6, 6;
}

.Fire_Ice {
	--mainColor: 180, 214, 255;
	--bgColor: 39, 10, 0;
}

.Chaos_Order {
	--mainColor: 218, 218, 218;
	--bgColor: 45, 2, 0;
}

.Classic {
	--mainColor: 253, 247, 144;
	--bgColor: 33, 18, 10;
}