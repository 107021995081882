.boxSimulatorContainer {
  height: 100%;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  mask-image: linear-gradient(to bottom, black 80%, transparent 95%);

  @include mobile-landscape {
    min-width: calc(100vw - 185px);
    margin-left: 185px;
  }

  @import './simHeader.scss';

  .boxSimulator {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    transition: all 0.25s linear;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    @include mobile-portrait {
      font-size: 14px;
      width: 300px;
      padding: 0;
    }

    @include mobile-landscape {
      margin-left: 95px;
      min-width: calc(100vw - 185px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    input {
      font-family: dosis;
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      width: 40px;
      text-align: center;
      margin: 0 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      outline: none;
      caret-color: white;
      transition: all 0.5s linear;

      @include desktop {
        font-size: 18px;
      }

      &:focus {
        border-bottom: 1px solid #{'rgb(var(--mainColor))'};
      }
    }

    @keyframes popIn {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      40% {
        opacity: 1;
        transform: scale(1);
      }
      80% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(0);
      }
    }

    .fa-check {
      opacity: 0;
      animation: popIn 1.25s ease-in-out 0.25s;
    }

    .table-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100vw;
      overflow-y: auto;
      height: calc(100vh - 260px);
      margin-top: 48px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @include mobile-portrait {
        margin-top: 57px;
      }

      @include mobile-landscape {
        height: calc(100vh - 150px);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        overflow: hidden;
        width: 375px;
        border-radius: 10px;
        padding-bottom: 150px;
        align-self: center;
        box-sizing: content-box;
        border-spacing: 0;
        position: absolute;
        top: -30px;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        transition: all 0.25s linear;
  
        @include mobile-portrait {
          width: 300px;
        }
  
        @include mobile-landscape {

        }
  
        @include desktop {
          width: 416px;
        }
  
        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
  
        thead {
          opacity: 0;
          pointer-events: none;
          border-radius: 10px 10px 0 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: table-header-group;
  
          tr {
            th {
              padding: 5px 0;
              border-right: 1px solid #2a2a2a;

              &:nth-child(3) {
                @include mobile-portrait {
                  
                  display: none;
                }
              }

              &:last-child {
                border-right: none;
              }
            }
          }
        }
  
        tbody {
          border-spacing: 0px;
  
          .simResults {
            height: 60px;
            opacity: 0.5;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.05);
  
            &:last-child {

              td:first-child {
                border-radius: 0 0 0 10px;
              }

              td:last-child {
                border-radius: 0 0 10px 0;
              }
            }
  
            td {
              padding: 0;
              border: none;
              transition: all 0.25s ease-in-out;
  
              &:nth-child(1) {
                width: 113px;
                padding: 0 10px;
                border-right: 1px solid rgba(0, 0, 0, 0.3);
                font-size: 14px;
  
                @include mobile-portrait {
                  padding: 0 5px;
                }
  
                @include mobile-landscape {
                  font-size: 16px;
                }
  
                @include desktop {
                  width: 141px;
                  font-size: 17px;
                }
              }
  
              &:nth-child(2) {
                width: 49px;
                border-right: 1px solid rgba(0, 0, 0, 0.3);
  
                @include desktop {
                  width: 53px;
                }
              }
  
              &:nth-child(3) {
                width: 77px;
  
                @include mobile-portrait {
                  border-right: 1px solid rgba(0, 0, 0, 0.3);
                  display: none;
                }
  
                @include desktop {
                  width: 85px;
                }
              }
  
              &:nth-child(4) {
                width: 56px;
                background-color: rgba(0, 0, 0, 0.3);
  
                img {
                  height: 50px;
                }
  
                @include mobile-portrait {
                }
              }
  
              &:nth-child(5) {
                width: 56px;
                border: none;
              }
            }
  
            .rare {
              color: var(--rareColor);
            }
  
            .uncommon {
              color: var(--uncommonColor);
            }
  
            .common {
              color: var(--commonColor);
            }
  
            &:nth-child(odd) {
              background-color: rgba(255, 255, 255, 0.15);
            }
          }
  
          .wanted {
            opacity: 1;
            background-color: rgba(16, 255, 0, 0.18);
  
            &:nth-child(odd) {
              background-color: rgba(16, 255, 0, 0.25);
            }
          }
  
          .unwanted {
            opacity: 1;
            background-color: rgba(255, 0, 0, 0.18);
  
            &:nth-child(odd) {
              background-color: rgba(255, 0, 0, 0.25);
            }
          }
        }
      }

      .fake-header {
        margin: 0;
        overflow: hidden;
        border-radius: 0;
        position: fixed;
        top: 120px;
        border-radius: 10px 10px 0 0;

        @include desktop {
          top: 136px;
        }

        thead {
          opacity: 1;
          border-radius: 10px 10px 0 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: table-header-group;
          pointer-events: unset;
          padding: 0;
        }
      }
  
      .hide-table {
        transform: translateY(100px);
        opacity: 0;
      }
  
      .show-table {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  .probabilitySim {
    position: absolute;
    top: 0;
    width: 375px;
    transition: all 0.25s linear;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    @include mobile-portrait {
      font-size: 14px;
      width: 325px;
      padding: 0;
    }

    @include mobile-landscape {
      min-width: calc(100vw - 185px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 415px;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    table {
      overflow: hidden;
      width: 375px;
      border-radius: 10px;
      margin-top: 10px;
      align-self: center;
      box-sizing: content-box;
      border-spacing: 0;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      transition: all 0.25s linear;

      @include mobile-portrait {
        width: 325px;
      }

      @include mobile-landscape {
      }

      @include desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
      }

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      thead {
        display: block;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 0, 0, 0.5);

        @include desktop {
          width: 415px;
        }

        tr {
          th {
            padding: 5px 0;
            font-size: 14px;
            border-right: 1px solid #2a2a2a;

            &:nth-child(1) {
              width: 60px;
              border-left: none;

              @include mobile-portrait {
                width: 58px;
              }

              @include desktop {
                width: 60px;
              }
            }

            &:nth-child(2) {
              width: 81px;

              @include mobile-portrait {
                width: 54px;
              }

              @include desktop {
                width: 91px;
              }
            }

            &:nth-child(3) {
              width: 84px;

              @include mobile-portrait {
                width: 64px;
              }

              @include desktop {
                width: 84px;
              }
            }

            &:nth-child(4) {
              width: 77px;

              @include mobile-portrait {
                width: 59px;
              }

              @include desktop {
                width: 87px;
              }
            }

            &:nth-child(5) {
              width: 69px;
              border-right: none;

              @include mobile-portrait {
                width: 82px;
              }

              @include desktop {
                width: 85px;
              }
            }
          }
        }
      }

      tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 330px);
        border-radius: 0 0 10px 10px;
        border-spacing: 0px;
        scrollbar-width: none;
        -ms-overflow-style: none;

        @include mobile-portrait {
          max-height: calc(100vh - 260px);
        }

        @include mobile-landscape {
          max-height: calc(100vh - 150px);
        }

        @include desktop {
          width: 100vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-height: calc(100vh - 310px);
        }

        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }

        tr {
          height: 60px;
          opacity: 1;
          text-align: center;

          &:last-child {
            margin-bottom: 100px;
            border-radius: 0 0 10px 10px;
          }

          @include desktop {
            width: 414px;
          }

          td {
            color: white;
            font-size: 18px;
            padding: 5px 0;
            border: none;
            transition: all 0.25s ease-in-out;

            @include desktop {
              height: 60px;
              padding: 0 5px;
            }

            &:nth-child(1) {
              width: 40px;
              padding: 0 10px;
              border-right: 1px solid rgba(0, 0, 0, 0.3);

              @include mobile-portrait {
                width: 38px;
              }

              @include desktop {
                width: 39px;
              }
            }

            &:nth-child(2) {
              width: 81px;
              border-right: 1px solid rgba(0, 0, 0, 0.3);

              @include mobile-portrait {
                width: 54px;
              }

              @include desktop {
                width: 81px;
              }
            }

            &:nth-child(3) {
              width: 84px;
              border-right: 1px solid rgba(0, 0, 0, 0.3);

              @include mobile-portrait {
                width: 64px;
              }

              @include desktop {
                width: 74px;
              }
            }

            &:nth-child(4) {
              width: 77px;
              border-right: 1px solid rgba(0, 0, 0, 0.3);

              @include mobile-portrait {
                width: 59px;
              }

              @include desktop {
                width: 77px;
              }
            }

            &:nth-child(5) {
              width: 69px;
              border: none;

              @include mobile-portrait {
                width: 82px;
              }

              @include desktop {
                width: 74px;
              }
            }
          }

          &:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.15);
          }

          &:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
      }
    }
  }

  .hideContainer {
    pointer-events: none;
    opacity: 0;
  }
}
