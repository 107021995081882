.sim-header {
    display: inline-grid;
    grid-template-columns: 52px 1fr;
    grid-template-rows: repeat(2, 42px);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    align-items: center;
    margin-top: 12px;

    @include mobile-portrait {
      width: 300px;
      grid-template-columns: 36px 1fr;
      grid-template-rows: repeat(2, 36px);
    }

    @include desktop {
      grid-template-rows: repeat(2, 50px);
      width: 412px;
    }

    .boxSimToggle {
      grid-area: 1 / 1 / 3 / 2;
      z-index: 1;
      pointer-events: none;
      display: flex;
      flex-direction: column;
  
      .toggleContainer {
        pointer-events: all;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: 0;
        height: 22px;
        width: 34px;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        padding: 8px;
        border: 1px solid transparent;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.1s linear;
  
        @include mobile-portrait {
          width: 18px;
          color: transparent;
          border-radius: 0 0 10px 10px;
        }
  
        @include desktop {
          height: 30px;
        }
  
        &:first-child {
          margin-bottom: 4px;
  
          @include mobile-portrait {
            margin: 0;
            border-radius: 10px 10px 0 0;
          }
  
          @include mobile-landscape {
            margin-bottom: 6px;
          }

          @include desktop {
            margin-bottom: 6px;
          }
        }
  
        i {
          font-size: 18px;
          margin-bottom: 5px;
          display: flex;
          flex-direction: column;
          transition: all 0.1s linear;
  
          @include mobile-portrait {
            font-size: 14px;
            color: white;
            margin-bottom: -13px;
          }
  
          @include mobile-landscape {
            font-size: 14px;
          }
        }
  
        &:hover {
          border: 1px solid #{'rgb(var(--mainColor))'};
          opacity: 0.8;
        }
  
        &.disable-btn {
          opacity: 0.5;
        }
      }
  
      .active {
        border: 1px solid #{'rgb(var(--mainColor))'};
  
        i {
          color: #{'rgb(var(--mainColor))'};
        }
      }
    }

    .searchContainer {
      grid-area: 1 / 2 / 2 / 3;
      display: flex;
      height: 40px;
      border-radius: 10px;

      @include mobile-portrait {

      }

      @include mobile-landscape {
        width: 315px;
      }

      @include desktop {
        height: 50px;
      }

      h4 {
        display: flex;
        width: 157px;
        height: 40px;
        justify-content: center;
        margin: 0;
        align-items: center;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.5);

        @include mobile-portrait {
          width: 110px;
          font-size: 14px;
        }

        @include mobile-landscape {
          width: 152px;
        }

        @include desktop {
          font-size: 20px;
          height: 50px;
          width: 173px;
        }
      }

      .box-input {
        border-radius: 10px 0 0 10px;
        padding-right: 5px;
        margin-right: -25px;

        @include mobile-portrait {
          width: 95px;
          padding: 0 25px 0 0;
          justify-content: flex-end;
        }
      }

      .points-amount {
        border-radius: 0 10px 10px 0;
        padding-left: 5px;
        margin-left: -25px;

        @include mobile-portrait {
          width: 80px;
          padding: 0 45px 0 20px;
          justify-content: end;
        }

        @include mobile-landscape {
          padding-left: 15px;
        }
      }

      button {
        z-index: 1;
        cursor: pointer;
        color: #{'rgb(var(--bgColor))'};
        height: 40px;
        width: 40px;
        font-family: dosis;
        font-weight: 600;
        font-size: 18px;
        border: none;
        border-radius: 100px;
        outline: none;
        background-color: #{'rgb(var(--mainColor))'};
        transition: all 0.25s linear;

        &::-moz-focus-inner {
          border: 0;
        }

        @include mobile-portrait {
          height: 40px;
          width: 40px;
        }

        @include desktop {
          height: 50px;
          width: 50px;
        }
      }

      .disable-btn {
        filter: brightness(0.2);
      }
    }

    .simInfoContainer {
      grid-area: 2 / 2 / 3 / 3;
      display: flex;
      flex-direction: row;

      @include mobile-portrait {

      }

      @include mobile-landscape {
        margin-top: 6px;
        width: 315px;
      }

      @include desktop {
        width: 406px;
      }

      .submitResults {
        max-width: 132px;
        flex-grow: 1;
        height: 38px;
        cursor: pointer;
        font: inherit;
        outline: none;
        color: white;
        margin-right: 8px;
        opacity: 0.7;
        text-align: center;
        align-self: center;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 5px;
        border: 1px solid #{'rgb(var(--mainColor))'};
        border-radius: 10px;
        transition: all 0.25s linear;

        @include mobile-portrait {
          width: 95px;
          height: auto;
          top: 0px;
          font-size: 14px;
          position: relative;
        }

        @include mobile-landscape {
          width: 115px;
        }

        @include desktop {
          height: auto;
          font-size: 20px;
          width: 135px;
        }

        &:hover {
          opacity: 1;
        }
      }

      .submitted {
        cursor: default;
        opacity: 1;
        background-color: transparent;
        border-color: transparent;
        color: #{'rgb(var(--mainColor))'};
        pointer-events: none;
      }

      .pending {
        pointer-events: none;
      }

      .hideResults {
        cursor: default;
        pointer-events: none;
        opacity: 0.2;

        &:hover {
          opacity: 0.2;
        }
      }

      .profitContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 16px;
        padding: 6px 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        flex-grow: 1;
        max-width: 150px;

        @include mobile-portrait {
          width: 127px;
          font-size: 14px;
        }

        @include mobile-landscape {
          width: 164px;
        }

        @include desktop {
          font-size: 20px;
          max-width: 192px;
        }

        span:nth-child(1) {
          color: #0d0;
          padding-right: 3px;
          font-weight: 600;
        }

        span:nth-child(2) {
          color: #d00;
          padding-left: 3px;
          padding-right: 2px;
          font-weight: 600;
        }

        .amount,
        .amount-positive,
        .amount-negative {
          padding: 0 4px;
        }

        .amount-positive {
          color: #0d0;
        }

        .amount-negative {
          color: #d00;
        }
      }
    }

    .selectorContainer {
      grid-area: 1 / 2 / 2 / 3;
      display: flex;
      align-items: center;
      height: 40px;
      border-radius: 10px;
      transition: all 0.25s linear;

      @include mobile-portrait {
      }

      @include mobile-landscape {
        width: 315px;
      }

      @include desktop {
        height: 50px;
      }

      h4 {
        display: flex;
        width: 110px;
        height: 40px;
        margin: 0;
        align-items: center;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.5);

        @include mobile-portrait {
          font-size: 14px;
        }

        @include mobile-landscape {
          width: 125px;
        }

        @include desktop {
          font-size: 18px;
          height: 50px;
          width: 121px;
        }
      }

      .boxSelector {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
        justify-content: center;
        padding-right: 20px;
        margin-right: -25px;
        font-size: 14px;
        border: 1px solid transparent;
        transition: all 0.25s linear;

        @include mobile-portrait {
          width: 87px;
          padding: 0 25px 0 0;
          justify-content: flex-end;
        }

        @include desktop {
          font-size: 16px;
          height: 48px;
        }

        span {
          z-index: 1;
          text-shadow: 0px 0px 2px black;
        }

        img {
          height: 78px;
          position: absolute;
          left: calc(50% - 45px);
          top: -20px;
          opacity: 0.6;
        }
      }

      button {
        z-index: 1;
        cursor: pointer;
        color: white;
        height: 50px;
        width: 50px;
        font-family: dosis;
        font-weight: 600;
        font-size: 18px;
        border: none;
        border-radius: 100px;
        outline: none;
        background-color: #{'rgb(var(--mainColor))'};
        color: #{'rgb(var(--bgColor))'};
        transition: all 0.25s linear;

        &::-moz-focus-inner {
          border: 0;
        }

        @include mobile-portrait {
          height: 40px;
          width: 40px;
        }

        i {
          margin-left: 4px;
        }
      }

      .activeFilter {
        color: #{'rgb(var(--mainColor))'};
        border: 1px solid #{'rgb(var(--mainColor))'};
      }

      .react-select {
        margin-left: -22px;
        transition: all 0.25s linear;

        .react-select__control {
          cursor: pointer;
          height: 42px;
          width: 181px;
          background: rgba(0, 0, 0, 0.5);
          border: 1px solid transparent;
          border-radius: 0 10px 10px 0;
          transition: all 0.25s linear;

          @include mobile-portrait {
            width: 177px;
          }

          @include desktop {
            width: 211px;
            height: 50px;
          }

          .react-select__value-container {
            height: 100%;

            .react-select__single-value {
              height: 100%;
              margin-left: 20px;

              .listItem {
                height: 100%;
                font-size: 13px;
                font-weight: 600;
                white-space: normal;
                color: white;
                display: flex;
                align-items: center;
                transition: all 0.25s linear;

                img {
                  display: none;
                  margin-left: 5px;
                  height: 30px;

                  @include desktop {
                    display: block;
                  }
                }
              }
            }
          }

          .react-select__indicators {
            .react-select__indicator-separator {
              background-color: white;
              transition: all 0.25s linear;
            }

            .react-select__dropdown-indicator {
              color: white;
              transition: all 0.25s linear;
            }
          }
        }

        .react-select__control--is-focused {
          box-shadow: none;
        }

        .react-select__menu {
          overflow: hidden;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.9);

          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          &:-webkit-scrollbar {
            display: none; /* Chrome */
          }

          .react-select__menu-list {
            padding: 0;

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
              display: none; /* Chrome */
            }

            .react-select__option {
              cursor: pointer;
              display: flex;
              width: 100%;
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                background-color: rgba(255, 255, 255, 0.05);
              }

              .listItem {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                  height: 30px;
                }
              }
            }

            .react-select__option--is-focused {
              background-color: rgba(255, 255, 255, 0.05);
            }

            .react-select__option--is-selected {
              color: #{'rgb(var(--bgColor))'};
              background-color: #{'rgb(var(--mainColor))'};

              &:hover {
                color: inherit;
              }
            }
          }
        }
      }

      .activeSelect {
        .react-select__control {
          border: 1px solid #{'rgb(var(--mainColor))'};

          .react-select__value-container {
            .react-select__single-value {
              .listItem {
                color: #{'rgb(var(--mainColor))'};
              }
            }
          }

          .react-select__indicators {
            .react-select__indicator-separator {
              background-color: #{'rgb(var(--mainColor))'};
            }

            .react-select__dropdown-indicator {
              color: #{'rgb(var(--mainColor))'};
            }
          }
        }
      }

      .disable-btn {
        filter: brightness(0.2);
      }

      .disabledContainer {
        filter: brightness(0.2);
        pointer-events: none;
      }
    }

    .input-container {
      grid-area: 2 / 2 / 3 / 3;
      display: flex;

      @include mobile-landscape {

      }

      @include desktop {

      }

      .iteration-input,
      .boxes-input {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        border-radius: 10px;
        padding: 0 10px;
        margin: 0;
        height: 38px;
        width: 133px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.25s linear;

        @include mobile-portrait {
          font-size: 15px;
          width: 121px;
          height: 30px;
          padding: 0 8px;
        }

        @include mobile-landscape {
          width: 132px;
        }

        @include desktop {
          width: 153px;
        }

        input {
          font-family: dosis;
          color: rgba(255, 255, 255, 0.7);
          font-size: 16px;
          width: 30px;
          text-align: center;
          margin: 0 5px;
          background: transparent;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          outline: none;
          caret-color: white;
          transition: all 0.5s linear;

          &:focus {
            border-bottom: 1px solid #{'rgb(var(--mainColor))'};
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      .boxes-input {
        margin-left: 10px;
      }
    }
  }